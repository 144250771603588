<template>
    <div class="packages" data-aos="fade-right">
      <div class="row">
        <div class="col-md-6 packages-img">
            <img class="img-left" data-aos="fade-right" :src="require(`@/assets/img/home-page/p1-${$i18n.locale === 'ar' ? 'ar.webp' : 'en.png'}`)" alt="package left" loading="lazy">
            <img class="img-center" data-aos="fade-down" :src="require(`@/assets/img/home-page/p2-${$i18n.locale === 'ar' ? 'ar.webp' : 'en.png'}`)" alt="package center" loading="lazy">
            <img class="img-right" data-aos="fade-left" :src="require(`@/assets/img/home-page/p3-${$i18n.locale === 'ar' ? 'ar.webp' : 'en.png'}`)" alt="package right" loading="lazy">
        </div>
        <div class="col-md-6 guide">
            <h1>{{ this.$i18n.locale == 'ar' ? titleAr1 : titleEn1 }}</h1>
            <p>{{ this.$i18n.locale == 'ar' ? DiscriptionAr : DiscriptionEn }}</p>
            <p>{{ this.$i18n.locale == 'ar' ? DiscriptionAr2 : DiscriptionEn2 }}</p>
            <router-link to="/packages">
              <BtnMain
            :title="$t('Learn More')"
            :TextColor="'#fff'"
            :hovercolor="'#0349A8'"
            :BackgroundColor="'transparent'"
            :hoverBackgroundColor="'#fff'"
            :Border="'#fff'"
            :hoverBorder="'#fff'" />
            </router-link>
        </div>
      </div>
    </div>
  </template>

<script>
  import { defineAsyncComponent } from 'vue'
  export default {
    data() {
      return {
        titleAr1: 'اختر باقة الاشتراك السنوية التي تناسب إحتياجك',
        titleEn1: 'Choose the annual subscription plan that suits your needs.',
        DiscriptionAr: 'توفر لك إتمام باقات مصممة خصيصًا لتلبية احتياجات أعمالك',
        DiscriptionEn: 'Etmaam offers specially designed plans to meet your business requirements',
        DiscriptionAr2: 'اختر من بين حلولنا المرنة التي تمنحك الدعم مما يمكّنك من التركيز على النمو بكل ثقة وسهولة',
        DiscriptionEn2: 'Select from our flexible solutions that provide you with the support you need, enabling you to focus on growth with confidence and ease.',

      };
    },
    components: {
      BtnMain: defineAsyncComponent( () => import('@/components/Global/BtnMain.vue') ),
   },
  }
</script>
